import {computed, ref} from "@vue/composition-api";
import store from "@/store";
import axios from "@/libs/axios";
const BACK_URL = process.env.VUE_APP_URL;

export default function useItemForm() {
    const products = ref([]);
    const lobbies = ref([]);
    const productSelected = ref({});
    const lobbySelected = ref({});
    const backURL = ref(BACK_URL);


    const resetSelections = () => {
        productSelected.value = {};
        lobbySelected.value = {};
    };

    return {
        products,
        lobbies,
        productSelected,
        lobbySelected,
        resetSelections,
    };
}