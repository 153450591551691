<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback, BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import vSelect from "vue-select";
import {computed, ref, onMounted} from "@vue/composition-api";
import store from "@/store";
import axios from "@/libs/axios";
import IconDropdown from "@/views/components/whitelabel-templates/common/IconDropDown.vue";
import {svgs} from "@/views/components/whitelabel-templates/common/assets/svgs";
import formValidation from "@core/comp-functions/forms/form-validation";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {useToast} from "vue-toastification/composition";
import useItemForm from "@/views/components/whitelabel-templates/common/composables/useItemForm";

const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "ItemForm",
  components: {
    BFormSelect,
    ImageFieldForm,
    IconDropdown,
    BRow,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BCol,
    vSelect,
  },
  props: {
    iconList: {
      type: Array,
      default: () => []
    },
    template: {
      type: Object,
      default: () => ({}),
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    sizeValidation: {
      type: Boolean,
      default: false
    },
    size: {
      type: String
    },
    item: {
      type: Object,
      default: () => ({
        name: "",
        src: "",
        href: "",
        id: "",
        buttonType: "",
        position: "",
        children: [],
        title: "",
        status: "",
        svgData: "",
        hoverColor: "",
        sub_title: "",
        variant: "",
        text: "",
      }),
    },
  },
  setup(props, {emit}) {
    const backURL = ref(BACK_URL);
    const formValid = ref(false);
    const toast = useToast();
    const options = ref(svgs);
    const itemCache = ref({});

    const {
      lobbySelected,
      lobbies,
      products,
      productSelected,
    } = useItemForm();


    const onSubmit = () => {
      if (props.settings.action === "edit") {
        emit("update");
      } else {
        emit("save");
      }
    };

    const variants = ref([
      {value: "casino", text: "Casino"},
      {value: "sports", text: "Sports"},
      {value: "racing", text: "Racing"},
    ]);

    const onCancel = () => {
      emit("reset", itemCache.value);
      props.settings.index = null
    };

    const linkButtonHandler = () => {
      emit(props.settings.action === "edit" ? "update" : "save");
    };

    const onUploadedImage = (data) => {
      if (data?.files.length) {
        props.item.src = data.files[data.files.length - 1];
      }
    };

    const onClearImage = () => {
      props.item.src = ''
    }

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const onIconSelected = (icon) => {
      if (icon) props.item.svgData = icon.value;
      else props.item.svgData = ''
      emit("iconSelected");
    };

    const fetchData = async () => {
      try {
        const whitelabelId = store.state.whitelabelCurrencyNabvar.whitelabel._id;
        const clientId = store.state.whitelabelCurrencyNabvar.whitelabel.client;
        const [productsResponse, lobbiesResponse] = await Promise.all([
          axios.get(`${backURL.value}/clients/availProd/${clientId}`),
          axios.get(`${backURL.value}/lobbies`, {
            params: {
              whitelabelId,
              templateId: props.template._id
            }
          }),
        ]);
        products.value = productsResponse.data.map(({product}) => ({
          productId: product.productId,
          name: product.name,
        }));
        lobbies.value = lobbiesResponse.data.lobbys;
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    const updateItemDetails = (val, type) => {
      if (val.position !== undefined) {
        props.item.position = val.position;
      } else if (type === "lobby") {
        lobbySelected.value = val;
        props.item.id = val._id;
        props.item.title = val.name;
        props.item.href = `/lobby?id=${val._id}`;
      } else if (type === "product") {
        productSelected.value = val;
        props.item.id = val.productId;
        props.item.title = val.name;
        props.item.href = `/product/${val.productId}`;
      }
    };

    const {refLinkFormObserver, getValidationState, resetForm} =
        formValidation(() => {
        });

    const lobby = computed({
      get: () => lobbySelected.value,
      set: (val) => {
        updateItemDetails(val, "lobby");
      },
    });

    const product = computed({
      get: () => productSelected.value,
      set: (val) => {
        updateItemDetails(val, "product");
      },
    });

    const saveCache = () => {
      itemCache.value = Object.assign({
        item: {...props.item},
        index: props.settings.index
      })
    }

    const filterIcons = () => {
       options.value = svgs.filter(icon => props.iconList.includes(icon.text));
    }

    onMounted(() => {
      saveCache()
      if (props.item.buttonType !== undefined) {
        fetchData()
      }

      if (props.iconList.length > 0) {
        filterIcons()
      }
    });

    return {
      formValid,
      onCancel,
      linkButtonHandler,
      onUploadedImage,
      refLinkFormObserver,
      getValidationState,
      resetForm,
      onSubmit,
      onClearImage,
      errorInLoadImage,
      onIconSelected,
      updateItemDetails,
      options,
      products,
      product,
      lobbies,
      lobby,
      variants
    };
  },
};
</script>

<template>
  <validation-observer
      ref="refLinkFormObserver"
      #default="{ handleSubmit, invalid }"
  >
    <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
      <b-list-group-item style="background: none" class="px-0">

        <b-row v-if="item.name !== undefined">
          <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
              class="w-100"
          >
            <b-col cols="12">
              <b-form-group :label="$t('labels.name')" label-for="name">
                <b-form-input
                    id="name"
                    v-model="item.name"
                    :state="getValidationState(validationContext)"
                    required/>
                <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row v-if="item.buttonType !== undefined">
          <b-col cols="12">
            <b-form-group :label="$t('button_type')" label-for="type">
              <v-select
                  v-model="item.buttonType"
                  label="text"
                  :options="['link', 'product', 'lobby']"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="item.buttonType === 'product'">
          <validation-provider
              #default="validationContext"
              name="product"
              rules="required"
              class="w-100"
          >
            <b-col cols="12">
              <b-form-group
                  :label="$t('labels.products')"
                  label-for="products"
              >
                <v-select
                    v-model="product"
                    :options="products"
                    label="name"
                    :state="getValidationState(validationContext)"
                    required
                />
                <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row v-if="item.buttonType === 'lobby'">
          <validation-provider
              #default="validationContext"
              name="lobby"
              rules="required"
              class="w-100"
          >
            <b-col cols="12">
              <b-form-group :label="$t('lobby')" label-for="lobby">
                <v-select
                    v-model="lobby"
                    :options="lobbies"
                    label="name"
                    track-by="name"
                    :state="getValidationState(validationContext)"
                    required
                />
                <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row>
          <validation-provider
              #default="validationContext"
              name="href"
              rules="required"
              class="w-100"
          >
            <b-col cols="12">
              <b-form-group :label="$t('labels.link')" label-for="href">
                <b-form-input
                    id="href"
                    v-model="item.href"
                    :state="getValidationState(validationContext)"
                    required
                    :disabled="item.buttonType === 'product' || item.buttonType === 'lobby'"/>
                <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row v-if="item.title !== undefined">
          <validation-provider
              #default="validationContext"
              name="title"
              rules="required"
              class="w-100"
          >
            <b-col cols="12">
              <b-form-group :label="$t('title')" label-for="title">
                <b-form-input
                    id="title"
                    v-model="item.title"
                    :state="getValidationState(validationContext)"
                    required
                />
                <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>


        <b-row v-if="item.hasOwnProperty('sub_title')">
          <b-col cols="12">
            <validation-provider
                #default="validationContext"
                name="subtitle"
                rules="required"
            >
              <b-form-group label="subtitle" label-for="c-subtitle">
                <template #label>{{ $t("subtitle") }}</template>
                <b-form-input
                    id="c-subtitle"
                    type="text"
                    v-model="item.sub_title"
                    :state="getValidationState(validationContext)"
                />
                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row v-if="item.src !== undefined">
          <div class="px-1 w-100 mt-1">
            <span v-if="sizeValidation">{{ size }}</span>
            <image-field-form
                :single-button="true"
                class="w-100"
                text="Images"
                :size-validation="sizeValidation ? size : null"
                :value="item.src"
                path="responsible_gaming"
                @uploadedImage="onUploadedImage($event)"
                @error="errorInLoadImage"
                :preview="false"
            >
            </image-field-form>

            <div class="row" v-if="item.src.length > 0">
              <div class="col">
                <img
                    style="max-width: 100%"
                    :src="item.src"
                    alt="Uploaded Image"
                    class="mt-1 rounded"
                />

                <b-button class="close-btn" @click="onClearImage()" variant="outline-primary" size="sm">
                  X
                </b-button>
              </div>
            </div>
          </div>
        </b-row>

        <b-row v-if="item.svgData !== undefined">
          <b-col cols="12">
            <b-form-group label="Icon" label-for="icon">
              <b-input-group>
                <IconDropdown
                    :icons="options"
                    :selected-icon-prop="item.svgData"
                    @icon-selected="onIconSelected($event, item)"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="item.position !== undefined">
          <validation-provider
              rules="required"
              #default="validationContext"
              name="position"
              class="w-100"
          >
            <b-col cols="12">
              <b-form-group label-for="position">
                <template #label>position</template>
                <b-form-input
                    id="position"
                    type="number"
                    min="0"
                    required
                    v-model="item.position"
                    :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row v-if="item.hoverColor !== undefined">
          <b-col cols="12">
            <b-form-group
                :label="$t('labels.hovercolor')"
                label-for="h-backgroundColor"
            >
              <b-form-input
                  id="h-hovercolor"
                  type="color"
                  v-model="item.hoverColor"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="item.variant !== undefined">
          <b-col cols="12">
            <validation-provider
                #default="validationContext"
                name="variant"
                rules="required"
            >
              <b-form-group label="variant" label-for="c-variant">
                <template #label>{{ $t("labels.variant") }}</template>

                <b-form-select
                    v-model="item.variant"
                    :options="variants"
                ></b-form-select>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row v-if="item.hasOwnProperty('text')">
          <b-col cols="12">
            <validation-provider
                #default="validationContext"
                name="text"
                rules="required"
            >
              <b-form-group label="text" label-for="cta_text">
                <template #label>{{ $t("labels.text") }}</template>

                <b-form-textarea
                    rows="6"
                    v-model="item.text"
                ></b-form-textarea>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!--        <b-row>-->
        <!--          <validation-provider-->
        <!--              rules="required"-->
        <!--              #default="validationContext"-->
        <!--              name="content"-->
        <!--              class="w-100"-->
        <!--          >-->
        <!--            <b-col class="border-md-left" cols="12">-->
        <!--              <b-form-group label-for="text">-->
        <!--                <template #label>{{ $t("content") }}</template>-->
        <!--                <b-form-input-->
        <!--                    @input="onChangeItem()"-->
        <!--                    id="title"-->
        <!--                    required-->
        <!--                    type="text"-->
        <!--                    v-model="item.content">-->

        <!--                </b-form-input>-->
        <!--              </b-form-group>-->
        <!--            </b-col>-->
        <!--          </validation-provider>-->
        <!--        </b-row>-->


      </b-list-group-item>

      <div class="mt-4 d-flex flex-row" style="gap: 5px">
        <b-button
            :disabled="invalid"
            type="submit"
            variant="primary"
        >
          {{
            settings.action === "edit"
                ? $t("buttons.update_and_apply")
                : $t("buttons.create_and_apply")
          }}
        </b-button>
        <b-button @click="onCancel" variant="secondary">{{
            $t("buttons.cancel")
          }}
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<style scoped lang="scss"></style>
