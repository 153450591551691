import {useToast} from "vue-toastification/composition";
import {computed, ref} from "@vue/composition-api";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";

export function UseIndex() {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    const toast = useToast();
    const debounceTimer = ref(null);
    const loading = ref(false);

    if (!store.hasModule(MODULE_APP_STORE_NAME))
        store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);

    const settings = ref({
        status: "list", // 'list' | 'form'
        action: "edit", // 'edit' | 'add'
        index: null
    });

    const editTemplate = computed(
        () => store.state[MODULE_APP_STORE_NAME].editTemplate
    );

    const showForm = () => {
        settings.value.status = "form";
    };

    const enableEditMode = () => {
        settings.value.action = "edit";
    };

    const enableAddMode = () => {
        settings.value.action = "add";
    };


    const showList = () => {
        settings.value.status = "list";
    };

    const setIndex = (index) => {
        settings.value.index = index
    }

    const {refFormObserver, getValidationState, resetForm} = formValidation(
        () => {
        }
    );


    return {
        settings,
        editTemplate,
        showForm,
        enableEditMode,
        toast,
        debounceTimer,
        loading,
        refFormObserver,
        getValidationState,
        setIndex,
        enableAddMode,
        resetForm,
        showList,
        MODULE_APP_STORE_NAME
    };
}

